/* Simple modern CSS normalization */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Main CSS starts here */

.container {
  width: clamp(16rem, 90vw, 75rem);
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.content {
  flex: 1 0 auto;
  margin: 0 auto 4rem auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
}

.center {
  text-align: center;
}

a {
  color: #17403e;
}

a:hover {
  text-decoration-thickness: 2px;
}

details summary {
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0.75rem;
  padding-top: 0.5rem;
}

details:not(:first-of-type) {
  border-top: 1px solid #e5e7eb;
}

details > summary {
  position: relative;
  list-style: none;
  font-weight: 600;
}

details summary::-webkit-details-marker {
  display:none;
}

details p {
  font-size: 1.2rem;
  color: #6b7280;
}

details > summary::before {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
}

details[open] > summary::before {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
}
