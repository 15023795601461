.nav-bar {
  background: white;
  box-shadow: 0 0.25rem 0.5rem #00000012;
  margin-bottom: 2rem;
}

nav {
  width: clamp(16rem, 90vw, 75rem);
  margin-left: auto;
  margin-right: auto;
}

ul {
  list-style-type: none;
}

nav a,
Link {
  color: #17403E;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

li a:hover {
  color: #fff;
  background-color: #17403E;
}

li a.active {
  color: #fff;
  background-color: #17403E;
}

.logo a.active, .logo a:hover {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.menu li {
  padding: 0 0.25rem;
}

/* Mobile Menu */

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

@media all and (min-width: 600px) {
  .logo {
    flex: 1;
  }
}

@media screen and (max-width: 992px) {
  .menu {
    flex-direction: column;
  }
  .menu li {
    margin-bottom: 1rem;
  }
}
